<template>
  <v-container class="mt-0">
    <v-row>
      <v-col class="col-12 col-md-3">
        <v-card outlined>
          <v-card-title class="font-weight-medium">
            {{ $ml.get("password_settings") }}
          </v-card-title>
        </v-card>
      </v-col>

      <v-col class="col-12 col-md-9">
        <v-card
          class="px-0 pb-5"
          outlined
          :loading="loadingBtn || loadingPassword"
        >
          <v-card-title class="text--secondary mb-2 mx-2">
            {{ $ml.get("set_default_password") }}
          </v-card-title>
          <v-card-subtitle class="mx-2">
            {{ $ml.get("password_settings_subtitle") }}
          </v-card-subtitle>
          <v-card-text class="mx-0 px-0">
            <v-expansion-panels v-model="showEdit" popout hover flat>
              <v-expansion-panel id="edit-tab" class="elevation-0 pb-0">
                <v-expansion-panel-header
                  disable-icon-rotate
                  expand-icon="mdi-pencil"
                  class="v-card__text px-6"
                >
                  <div>
                    <v-skeleton-loader
                      type="list-item-two-line"
                      v-if="loadingPassword & !hasDefaultPassword"
                      style="max-width: 200px"
                    ></v-skeleton-loader>
                    <div
                      v-else
                      class="d-flex align-start font-weight-bold text-body-1"
                    >
                      <v-icon
                        v-text="
                          hasDefaultPassword
                            ? 'mdi-check-circle'
                            : 'mdi-close-circle'
                        "
                        :color="hasDefaultPassword ? 'success' : 'error'"
                        size="22"
                        class="mr-2"
                      />
                      {{
                        hasDefaultPassword
                          ? $ml.get("has_initial_password")
                          : $ml.get("no_initial_password")
                      }}
                    </div>
                    <div
                      v-if="hasDefaultPassword"
                      class="text-subtitle-2 d-flex align-center ml-7 mt-3"
                    >
                      <!-- {{ defaultPassword }} -->
                      <span class="mr-3">
                        {{ $ml.get("current_password") }}:
                      </span>
                      <div
                        class="d-flex align-center mr-2"
                        style="height: 20px"
                      >
                        <code id="default_password">
                          {{ defaultPassword }}
                        </code>
                        <v-icon
                          @click.stop="copyPassword()"
                          v-text="'mdi-content-copy'"
                          class="ml-2"
                          size="18"
                        />
                      </div>
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-8 px-0 mx-0">
                  <v-card-text class="py-0 mx-0 mb-8 d-flex align-center">
                    <div class="font-weight-regular pr-4 text-body-1">
                      {{ $ml.get("define") }}
                      {{ $ml.get("new_initial_password").toLowerCase() }}
                    </div>
                    <v-text-field
                      v-model="newDefaultPassword"
                      class="ml-4 pt-0"
                      minLength="5"
                      maxLength="30"
                      style="max-height: 60px"
                      :append-icon="showText ? 'mdi-eye-off' : 'mdi-eye'"
                      :type="showText ? 'password' : 'text'"
                      :rules="companyRules"
                      @click:append="showText = !showText"
                      outlined
                    />
                  </v-card-text>

                  <v-card-actions class="mt-4 px-4 pb-0 mb-0">
                    <v-spacer />
                    <v-btn
                      @click="newDefaultPassword = ''"
                      :disabled="newDefaultPassword === ''"
                      elevation="0"
                      color="secondary lighten-2"
                      text
                    >
                      {{ $ml.get("cancel") }}
                    </v-btn>
                    <v-btn
                      elevation="0"
                      :loading="loadingBtn"
                      color="primary"
                      text
                      class="ml-4 px-4"
                      :disabled="disableSaveAction"
                      @click="setDefaultPassword"
                    >
                      {{ $ml.get("save") }}
                    </v-btn>
                  </v-card-actions>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col class="col-12 col-md-9" offset-md="3">
        <v-card class="pa-2" outlined :loading="loadingBtn || loadingPassword">
          <v-card-title class="text--secondary mb-2">
            {{ $ml.get("password_security") }}
          </v-card-title>
          <v-card-subtitle>
            {{ $ml.get("password_settings_subtitle2") }}
          </v-card-subtitle>
          <v-card-text
            @click="dialog = true"
            class="mt-2 d-flex justify-space-between align-center pointer"
          >
            <div>
              <!-- <v-progress-circular
                indeterminate
                v-if="loadingPassword"
                size="10"
                width="4"
              /> -->
              <v-skeleton-loader
                type="list-item-two-line"
                v-if="loadingPassword"
              ></v-skeleton-loader>
              <div
                v-else
                class="d-flex flex-column align-start font-weight-bold body-1"
              >
                <div class="my-1">
                  <v-icon
                    v-text="'mdi-check-circle'"
                    color="success"
                    size="22"
                    class="mr-2"
                  />
                  {{ $ml.get("min_len_label") }}
                  {{ passwordStrength.min_len }}
                </div>
                <div class="my-1">
                  <v-icon
                    v-text="
                      passwordStrength.has_lower
                        ? 'mdi-check-circle'
                        : 'mdi-close-circle'
                    "
                    :color="passwordStrength.has_lower ? 'success' : 'error'"
                    size="22"
                    class="mr-2"
                  />
                  {{ $ml.get("checkbox_lowercase_label") }}
                </div>

                <div class="my-1">
                  <v-icon
                    v-text="
                      passwordStrength.has_upper
                        ? 'mdi-check-circle'
                        : 'mdi-close-circle'
                    "
                    :color="passwordStrength.has_upper ? 'success' : 'error'"
                    size="22"
                    class="mr-2"
                  />
                  {{ $ml.get("checkbox_uppercase_label") }}
                </div>
                <div class="my-1">
                  <v-icon
                    v-text="
                      passwordStrength.has_number
                        ? 'mdi-check-circle'
                        : 'mdi-close-circle'
                    "
                    :color="passwordStrength.has_number ? 'success' : 'error'"
                    size="22"
                    class="mr-2"
                  />
                  {{ $ml.get("checkbox_numbers_label") }}
                </div>
                <div class="my-1">
                  <v-icon
                    v-text="
                      passwordStrength.has_symbol
                        ? 'mdi-check-circle'
                        : 'mdi-close-circle'
                    "
                    :color="passwordStrength.has_symbol ? 'success' : 'error'"
                    size="22"
                    class="mr-2"
                  />
                  {{ $ml.get("checkbox_symbols_label") }}
                </div>
              </div>
            </div>

            <v-btn icon elevation="0" @click="dialog = true">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <HeaderDialog
      id="edit-pass-strength"
      :title="$ml.get('password_security')"
      :show="dialog"
      :loading="loadingPassStrength"
      :disabledAction="disableStrengthSaveAction"
      :actionText="$ml.get('save')"
      width="670"
      text
      @close="cancelPasswordStrengthEditing"
      @action="setPasswordStrength"
    >
      <template v-slot:body>
        <v-row class="align-center mb-1">
          <v-col cols="12" class="pb-0 ml-1">
            <strong>{{ $ml.get("min_len_label") }}</strong>
          </v-col>
          <v-col cols="11" sm="6" md="5" class="py-0">
            <v-slider
              v-model="editPasswordStrength.min_len"
              max="50"
              min="5"
              thumb-color="accent"
              track-color="primary"
              hide-details
            >
              <template v-slot:prepend>
                <v-icon @click="editPasswordStrength.min_len--">
                  mdi-minus
                </v-icon>
              </template>

              <template v-slot:append>
                <v-icon @click="editPasswordStrength.min_len++">
                  mdi-plus
                </v-icon>
              </template>
            </v-slider>
          </v-col>
          <span class="title">
            {{ editPasswordStrength.min_len }}
          </span>
        </v-row>

        <v-row class="flex-column">
          <v-col class="pb-0">
            <strong>{{ $ml.get("chackbox_label") }}</strong>
          </v-col>
          <v-col class="pt-0">
            <v-checkbox
              v-model="editPasswordStrength.has_lower"
              hide-details
              :label="$ml.get('checkbox_lowercase_label')"
              color="accent"
              style="width: fit-content"
            />
            <v-checkbox
              v-model="editPasswordStrength.has_upper"
              hide-details
              :label="$ml.get('checkbox_uppercase_label')"
              color="accent"
              style="width: fit-content"
            />
            <v-checkbox
              v-model="editPasswordStrength.has_number"
              hide-details
              :label="$ml.get('checkbox_numbers_label')"
              color="accent"
              style="width: fit-content"
            />
            <v-checkbox
              v-model="editPasswordStrength.has_symbol"
              hide-details
              :label="$ml.get('checkbox_symbols_label')"
              color="accent"
              style="width: fit-content"
            />
          </v-col>
        </v-row>
      </template>
    </HeaderDialog>
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import HeaderDialog from "@/components/base/HeaderDialog";
export default {
  name: "PasswordSettings",
  components: { HeaderDialog },
  data() {
    return {
      showEdit: false,
      dialog: false,
      loadingPassword: true,
      editPasswordStrength: {
        has_lower: false,
        has_number: false,
        has_symbol: false,
        has_upper: false,
        min_len: 0,
      },
      showText: true,
      loadingBtn: false,
      loadingPassStrength: false,
      newDefaultPassword: "",
      rules: {
        equalPasswords: (value) =>
          value === this.userPassword.password ||
          this.$ml.get("passwords_do_not_match"),
        min_len: (v) =>
          v.length >= this.minimumSize ||
          `${this.$ml.get("min_len_label")} ${this.minimumSize}`,
        has_upper: (v) =>
          /[A-Z]/.test(v) || this.$ml.get("must_have_a_upper_letter"),
        has_lower: (v) =>
          /[a-z]/.test(v) || this.$ml.get("must_have_a_lower_letter"),
        has_number: (v) => /\d/.test(v) || this.$ml.get("must_have_a_number"),
        has_symbol: (v) =>
          /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(v) ||
          this.$ml.get("must_have_a_symbol"),
      },
      passwordStrengthRules: [],
    };
  },

  watch: {
    passwordStrength() {
      this.editPasswordStrength = Object.assign({}, this.passwordStrength);
      this.initRules();
    },
  },

  computed: {
    ...mapGetters(["passwordStrength", "defaultPassword"]),

    companyRules() {
      return this.passwordStrengthRules;
    },

    minimumSize() {
      return this.passwordStrength.min_len;
    },

    hasDefaultPassword() {
      return !!this.defaultPassword;
    },

    disableSaveAction() {
      return (
        this.defaultPassword === this.newDefaultPassword ||
        this.loadingBtn ||
        !this.newDefaultPassword ||
        this.newDefaultPassword.length < 5
      );
    },

    disableStrengthSaveAction() {
      return (
        JSON.stringify(this.passwordStrength) ===
        JSON.stringify(this.editPasswordStrength)
      );
    },
  },

  methods: {
    ...mapActions(["setSnackbarDefault", "editDefaultPassword"]),

    ...mapMutations(["setNewCompanyValue"]),

    copyPassword() {
      var elm = document.getElementById("default_password");

      try {
        var selection = window.getSelection();
        var range2 = document.createRange();
        range2.selectNodeContents(elm);
        selection.removeAllRanges();
        selection.addRange(range2);
        document.execCommand("Copy");
        this.setSnackbarDefault({
          color: "success",
          message: "password_successful_copy",
        });
      } catch (e) {
        this.setSnackbarDefault({
          color: "error",
          message: "password_unable_to_copy",
        });
      }
    },

    setDefaultPassword() {
      this.loadingBtn = true;
      const payload = {
        password: this.newDefaultPassword,
      };
      this.editDefaultPassword(payload).finally(() => {
        this.loadingBtn = false;
        this.newDefaultPassword = "";
        this.showEdit = false;
      });
    },

    setPasswordStrength() {
      this.loadingPassStrength = true;

      this.$axios
        .patch("/companies/password-strength", this.editPasswordStrength)
        .then(({ data }) => {
          this.setNewCompanyValue(data);

          this.initRules();
          this.setSnackbarDefault({
            message: "success_update_password_strength",
          });
        })
        .catch((error) => {
          this.setSnackbarDefault({
            color: "error",
            message: "error_update_password_strength",
          });
          console.error(error);
        })
        .finally(() => {
          this.loadingPassStrength = false;
          this.dialog = false;
        });
    },

    cancelPasswordStrengthEditing() {
      this.dialog = false;
      this.editPasswordStrength = Object.assign({}, this.passwordStrength);
    },

    initRules() {
      const functionRulesApplied = [];

      for (let key in this.passwordStrength) {
        if (this.passwordStrength[key]) {
          functionRulesApplied.push(this.rules[key]);
        }
      }

      this.passwordStrengthRules = [...functionRulesApplied];
    },
  },
  beforeMount() {
    this.initRules();
    this.editPasswordStrength = Object.assign({}, this.passwordStrength);
    this.loadingPassword = false;
  },
};
</script>
<style scoped>
.show-edit > i {
  visibility: hidden;
}
.show-edit:hover > i {
  visibility: visible;
}
.pointer {
  cursor: pointer;
}
</style>
